import React from 'react';

const PaybillsSummary = (props) => {
    return (
        <p className="main_p4 mt-4 mb-4 pt-2 text-center mx-auto">
            { props.isLoaded ? props.Description:  ""}
            <br/><br/>
            { props.isLoaded ? props.Description2:  ""}
        </p>
    );
}

export default PaybillsSummary;
