import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import logo from '../images/logo.jpg'
import moment from 'moment-timezone';

class Navigation extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {visible: false};
        this.navClicked = this.navClicked.bind(this);
        this.linkClicked = this.linkClicked.bind(this);
    }
    
    componentDidMount() {
        let t = moment().tz('America/New_York');

        let day = t.isoWeekday();
        let militaryTime = t.format('HHmm');

        // Determines whether it is business hours, changes online icon if it is.
        if (day == 6 || day == 7 || militaryTime < 900 || militaryTime > 1700) {
            document.getElementById('online').src = 'https://pmgadmin.s3.amazonaws.com/fbb8b763892c4b46b889ec45264ee23d.png';
            document.getElementById('onlineText').innerHTML = "Unavailable"
        }
    }

    navClicked(e) {
        this.setState({visible: !this.state.visible})
    }

    linkClicked(e) {
        this.setState({visible: false})
    }

    render() {
        return(
        <section className="main-content" id="nav">
            {/* Conditionally render logo in top left (Large devices) */}
            { this.props.location.pathname !== '/' && <img id="nav-logo" src={logo}/> }
            
            {/* Conditional mobile nav backdrop */}
            <div onClick={this.linkClicked} className={this.state.visible ? 'backdrop-visible' : 'backdrop-hidden'} id="mobile-nav-backdrop"></div>

            {/* Navigation links (Mobile) */}     
            <div id="mobile-nav" className={this.state.visible ? 'nav-visible' : 'nav-hide'}>
                <i onClick={this.linkClicked} className="fa fa-times" id="close-nav"></i>
                <div className="mt-30-pc d-table text-left m-0-auto">
                    <h4 className="text-white f-serif">PMG</h4>
                    <ul className="mt-2 ls-none mobile-nav-links">
                        <li className="mr-lg-4 mr-3"><NavLink onClick={this.linkClicked} to="/" exact activeClassName="active-mobile">Home</NavLink></li>
                        <li className="mr-lg-4 mr-3"><NavLink onClick={this.linkClicked} to="/services" exact activeClassName="active-mobile">Services</NavLink></li>
                        <li className="mr-lg-4 mr-3"><NavLink onClick={this.linkClicked} to="/team" exact activeClassName="active-mobile">Team</NavLink></li>

                        <li className="mr-lg-4 mr-3"><NavLink onClick={this.linkClicked} to="/contact" exact activeClassName="active-mobile">Contact</NavLink></li>

                        <li className="mr-lg-4 mr-3"><NavLink onClick={this.linkClicked} to="/paybills" exact activeClassName="active-mobile">Paybills</NavLink></li>
                        <li className="mr-lg-4 mr-3"><a onClick={this.linkClicked} target="_blank" href="https://pmgcpa.sharefile.com/Authentication/Login">PMG Sharefile</a></li>
                        <li className="mr-lg-4 mr-3"><NavLink onClick={this.linkClicked} to="/newsletter" exact activeClassName="active-mobile">Newsletter</NavLink></li>
                    </ul>
                </div>
            </div>

            {/* Secondary navbar items (Location, phone, etc...) */}     
            <div className="layer">
                <div className="top-bar-w3layouts pt-4">
                    <div className="container-fluid px-lg-5">
                        <div className="row">
                            <div className="col-xl-12 top-social-agile text-md-right text-center mt-md-0 mt-2">
                                <div className="row right-top-info">
                                    <div className="col-md-12 header-top_w3layouts text-xl-right">
                                        <p className="mr-2 con-nav-item">
                                            <i className="fa fa-map-marker-alt mr-2"></i>Armonk, NY
                                        </p>
                                        <p className="con-nav-item">
                                            <i className="fa fa-phone mr-2"></i><a href="tel:1-914-273-8300">(914) 273-8300</a>
                                        </p>
                                        <p className="con-nav-item">
                                            <i className="fa fa-fax mr-2"></i> (914) 273-8301
                                        </p>
                                        <p className="con-nav-item">
                                            <i className="fa fa-clock mr-2"></i> 9:00 - 5:00 M-F
                                        </p>
                                        <p className="con-nav-item">
                                            <img id="online"
                                                src="https://pmgadmin.s3.amazonaws.com/79c988382deb467b88552240330ef814.png"
                                                style={{ width: "7px", marginTop: "-3px" }} />
                                        </p>
                                        <p id="onlineText">Available</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <header className="header">
                    {/* Conditionally render logo (Small devices) */}
                    { this.props.location.pathname !== '/' && <img id="nav-logo-2" src={logo}/> }
                    
                    {/* Navigation links */}
                    <div className="container-fluid px-lg-5">
                        <nav className="py-4">
                            <div id="logo"></div>
                            <label htmlFor="drop" className="toggle"><span className="fa fa-bars menu-icon"
                                aria-hidden="true"></span></label>
                            <input type="checkbox" id="drop" onClick={this.navClicked} />
                            <ul className="menu mt-4 bg-white">
                                <li className="mr-lg-4 mr-3">
                                    <NavLink to="/" exact activeClassName="active">Home</NavLink>
                                </li>
                                <li className="mr-lg-4 mr-3">
                                    <NavLink to="/services" exact activeClassName="active">Services</NavLink>
                                </li>
                                <li className="mr-lg-4 mr-3">
                                    <NavLink to="/team" exact activeClassName="active">Team</NavLink>
                                </li>
                                <li className="mr-lg-4 mr-3">
                                    <NavLink to="/contact" exact activeClassName="active">Contact</NavLink>
                                </li>
                                <li className="mr-lg-4 mr-3">
                                    <NavLink to="/paybills" exact activeClassName="active">Paybills</NavLink>
                                </li>
                                <li className="mr-lg-4 mr-3">
                                    <a target="_blank" href="https://pmgcpa.sharefile.com/Authentication/Login">PMG Sharefile</a>
                                </li>
                                <li className="mr-lg-4 mr-3">
                                    <NavLink to="/newsletter" exact activeClassName="active">Newsletter</NavLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </header>
            </div>
        </section>
        );
    };
}

export default withRouter(Navigation);