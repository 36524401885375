import React from 'react';

import PaybillsHeader from './components/PaybillsHeader';
import PaybillsForm from './components/PaybillsForm';
import PaybillsSummary from './components/PaybillsSummary';

const Paybills = (props) => {
    let paybillProps;
    if (props.isLoaded) {
        paybillProps = props.result[0].Paybills;

        return (
            <section className="contact py-5" id="paybills">
                <PaybillsHeader />
                
                <div className="container text-center" >
                    <div className="contact-top ">
                        <PaybillsSummary {...paybillProps} isLoaded={props.isLoaded}/>
                        <PaybillsForm {...paybillProps} isLoaded={props.isLoaded}/>
                    </div>
                </div>
            </section>
        )
    } else {
        paybillProps = {};

        return (
            <section id="home">
                <div class="loader" style={{marginTop : '30vh'}}></div>
            </section>);
    }
}

export default Paybills;