import React from 'react'

const PaybillsForm = (props) => {
    return (
        <div id="btnPayFrm">
            <a href={ props.isLoaded ? props.PaymentUrl:  ""} className="btn btn--pay">
                <h6 className="text-center">Continue to Payment Gateway</h6>
            </a> 
            <div class="cards cards--s">
                <img src="https://pmcollp.securepayments.cardpointe.com/admin/hpp/img/cc--visa.png" alt=""/> 
                <img src="https://pmcollp.securepayments.cardpointe.com/admin/hpp/img/cc--mastercard.png" alt=""/> 
                <img src="https://pmcollp.securepayments.cardpointe.com/admin/hpp/img/cc--discover.png" alt=""/> 
                <img src="https://pmcollp.securepayments.cardpointe.com/admin/hpp/img/cc--amex.png" alt=""/>
            </div>
            {/*<link href="https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800|Material+Icons" rel="stylesheet" type="text/css" id="customFont" />*/}
        </div>
        
    );
}

export default PaybillsForm;