import React from 'react';


class Newsletter extends React.Component {
    render() {
        return (
            <section>
                <div>
                    <iframe frameborder="0" height="1000" width="100%" name="sitetools" src="https://cp7.cpasitesolutions.com/~pmcollpc/newsletter.php" ></iframe>
                </div>
            </section>
        )
    }
}

export default Newsletter;