import React, { Component } from 'react';

import defaultProPic from '../../../images/defaultProPic.jpg'

class TeamBioLarge extends Component {

    render() {
        const Pic = () =>
            (<div className={(this.props.descriptionSmall ? "col-md-6 " : "col-md-4 ") + (this.props.orientation === "left" ? "team-img-l" : "team-img-r")}>
                <img alt={this.props.name} className="team-img-actual" src={this.props.photo == null ? defaultProPic : this.props.photo.url} />
            </div>);

        const Info = () =>
            (
                <div className={(this.props.descriptionSmall ? "col-md-6 " : "col-md-8 ") + "p-4 bg-main text-white " + (this.props.orientation === "left" ? "team-desc-l" : "team-desc-r order-md-first")}>
                    <h3>{this.props.name}</h3>
                    <h6>{this.props.position}</h6>
                    {this.props.email ? <React.Fragment><i className="fa fa-envelope ft-pt8-em d-inline"></i><h6 className="d-inline ml-1">{this.props.email}</h6></React.Fragment> : ''}

                    <p className="mt-3">{this.props.desc}</p>
                </div>
            );

        return (
            <div className="row mt-5">
                <Pic />
                <Info />
            </div>
        );
    };
}

export default TeamBioLarge;